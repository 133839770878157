<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loadingPdf"
            :disabled="loadingPdf"
            @click="downloadPdf"
            icon
          >
            <v-icon>$PdfDownload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download logs as PDF") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <date-picker
            v-model="interval"
            :allowedDates="allowedDates"
            today
            range
            sortRange
            icon
            v-on="on"
          />
        </template>
        <span>{{ $t("change interval") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="loading"
            :loading="loading"
            @click="getLogs()"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredLogs"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="time"
        sort-desc
        :hide-default-footer="logs.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="cage"
                :items="cages"
                item-text="name"
                item-value="id"
                :label="$t('cage')"
                chips
                dense
                :clearable="!isMobile"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="category"
                :items="categories"
                item-text="text"
                item-value="value"
                :label="$t('category')"
                chips
                multiple
                dense
                :clearable="!isMobile"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-outer-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                :clearable="!isMobile"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.time="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.time ? formatDateTime(item.time) : "-" }}
          </div>
        </template>
        <template v-slot:item.cage="{ item }">
          {{ item.cage.cage }}
          {{
            item.cage.rack_additional ? " - " + item.cage.rack_additional : ""
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-access-logs-wish-open
              :product-id="item.product_id"
              :time="item.time"
              :realtime="false"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import DatePicker from "@/components/basics/DatePicker";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue.vue";
import RzoAccessLogsWishOpen from "@/components/services/rzo/RzoAccessLogsWishOpen";

export default {
  name: "RzoAccessLogsRealtime",
  components: {
    DatePicker,
    BooleanValue,
    RzoAccessLogsWishOpen,
  },
  mixins: [downloadFile, formatDateTime, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    cages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    localProductId: null,
    logs: [],
    loading: false,
    loadingPdf: false,
    search: "",
    interval: [],
    cage: null,
    category: [],
    categories: [
      { text: "scharf", value: "SCHARF" },
      { text: "unscharf", value: "UNSCHARF" },
      { text: "MK_geschlossen", value: "ML_GESCHLOSSENn" },
      { text: "MK_offen", value: "MK_OFFEN" },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("time"),
          value: "time",
        },
        {
          text: this.$i18n.t("cage"),
          value: "cage",
        },
        {
          text: this.$i18n.t("category"),
          value: "cage.action",
        },
        {
          text: this.$i18n.t("description"),
          value: "descr",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    filteredLogs() {
      var that = this;
      return this.logs.filter(function (log) {
        return (
          that.category.length == 0 ||
          that.category.filter(function (cat) {
            return log.cage.action == cat;
          }).length > 0
        );
      });
    },
  },
  watch: {
    "$store.state.session": function () {
      this.logs = [];
      this.getLogs();
    },
    interval() {
      this.getLogs();
    },
    cage(value) {
      this.localProductId = value;
      this.getLogs();
    },
  },
  methods: {
    customFilter(value, search, item) {
      return (
        item.name.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
        item.descr.toUpperCase().indexOf(search.toUpperCase()) >= 0
      );
    },
    allowedDates() {
      return true;
    },
    getLogs() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/rzo/access/logs-realtime", {
          params: {
            product_id: this.localProductId,
            time_after:
              this.interval.length > 0 ? this.interval[0] + " 00:00:00" : null,
            time_before:
              this.interval.length > 1 ? this.interval[1] + " 23:59:59" : null,
          },
        })
        .then((response) => {
          var id = 0;
          that.logs = response.data.map(function (item) {
            id = id + 1;
            return { id: id, ...item };
          });
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    downloadPdf() {
      var that = this;
      this.loadingPdf = true;
      this.downloadFile(
        "services/rzo/access/logs-realtime/captouches",
        "get",
        {
          product_id: this.localProductId,
          time_after:
            this.interval.length > 0 ? this.interval[0] + " 00:00:00" : null,
          time_before:
            this.interval.length > 1 ? this.interval[1] + " 23:59:59" : null,
        },
        "access-log.pdf",
        "application/pdf",
        function () {
          that.loadingPdf = false;
        },
        function () {},
        true
      );
    },
  },
  mounted() {
    this.interval = [
      this.$moment().add(-30, "days").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ];
    this.localProductId = this.productId;
  },
};
</script>

<i18n>
{
  "en": {
    "reload": "reload",
    "change interval": "change interval",
    "download logs as PDF": "download logs as PDF",
    "cage": "Cage",
    "time": "Time",
    "category": "Category",
    "description": "Description",
    "value": "Value",
    "scharf": "activated",
    "unscharf": "inactive",
    "MK_geschlossen": "MK closed",
    "MK_offen": "MK open"
  },
  "de": {
    "reload": "neu laden",
    "change interval": "Interval ändern",
    "download logs as PDF": "Protokoll als PDF laden",
    "cage": "Cage",
    "time": "Zeit",
    "category": "Kategorie",
    "description": "Beschreibung",
    "value": "Wert",
    "scharf": "scharf",
    "unscharf": "unscharf",
    "MK_geschlossen": "MK geschlossen",
    "MK_offen": "MK offen"
  }
}
</i18n>
